import { ResponderLayout } from '../../containers';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React, { ReactElement } from 'react';
import dynamic from 'next/dynamic';

const ParticipatedAssignmentContainerDynamic = dynamic(
  () => import('../../containers/participated-assignment'),
);
const ParticipatedAssignment = () => <ParticipatedAssignmentContainerDynamic />;

export const getStaticProps = async (context: any) => {
  const { locale } = context;
  if (locale === undefined) {
    throw new Error('locale is missing');
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
};
ParticipatedAssignment.getLayout = function getLayout(page: ReactElement) {
  return <ResponderLayout>{page}</ResponderLayout>;
};
export default ParticipatedAssignment;
